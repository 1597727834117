import React from 'react';

import VectorMap, {
  Layer,
  Label,
  Tooltip,
  Legend,
  Source,
} from 'devextreme-react/vector-map';

import TooltipTemplate2021 from './tooltip2021';
import { kafue } from './kafue.js';
const bounds = [-180, 85, 180, -60];
const colorGroups = [0, 1, 2, 3];
const streamsPalette = ['Green', 'Red', 'Gray'];
const center = [27.056250000000038, -13.020828131543004];
var electionData = {};

export default function MapResults2021({ data = [] }) {

  // Update the document title using the browser API
  electionData = data;

  kafue.features.forEach(item => {

    const mdata = electionData[item.properties.fid];



    if (mdata) {

      mdata.pf2021 = Number(mdata.pf2021);
      mdata.upnd2021 = Number(mdata.upnd2021);
      mdata.other2021 = Number(mdata.other2021);

      mdata.pf = Number(mdata.pf);
      mdata.upnd = Number(mdata.upnd);
      mdata.other = Number(mdata.other);

      item.properties.name = mdata.name;
      item.properties.change = mdata.registeredChange;
      item.properties.data = mdata;
    } else {
      item.properties.name = item.properties.fid;
      item.properties.change = 0;
    }

  });


  return (
    <div>
      <VectorMap
        id="vectorMap"
        zoomFactor={25}
        bounds={bounds}
        center={center}
        zoomFactorChanged={zoomFactorChanged}
        onClick={clickHandler}>
        <Layer
          name='zambia'
          dataSource={kafue}
          customize={customizeLayer}
          colorGroupingField="tag"
          colorGroups={colorGroups}
          palette={streamsPalette}
        >
          <Label dataField="name" enabled={true} font={{ size: 8 }} />
        </Layer>
        <Tooltip enabled={true}
          contentRender={TooltipTemplate2021} />
        <Legend
          title='Political Party'
          horizontalAlignment="center"
          verticalAlignment="bottom"
          customizeText={customizeText}>
          <Source layer="zambia" grouping="color"></Source>
        </Legend>
      </VectorMap>

    </div>
  );
}


function customizeText(arg) {

  //console.log(arg);

  if (arg.color === '#ff0000') {
    return 'Hakainde Hichilema (UPND)';
  } else if (arg.color === '#008000') {
    return 'Edger Chagwa Lungu (PF)';
  } else {
    return 'Not yet called';
  }
}

function zoomFactorChanged({ zoomFactor }) {
  console.log("zoom has changed");
  console.log(zoomFactor);
};


function clickHandler({ target }) {

  if (target) {
    console.log(target.attribute('fid'));
    target.selected(!target.selected());

    console.log(electionData[target.attribute('fid')]);
  }
}

function customizeLayer(elements) {

  elements.forEach((element) => {

    const data = electionData[element.attribute('fid')];

    if (data) {

      let colorCode = '';

      if (data.pf2021 > data.upnd2021) {

        colorCode = "green";
      }
      else if (data.upnd2021 > data.pf2021) {
        colorCode = "red";
      }
      else {
        colorCode = "gray";
      }


      element.applySettings({
        color: colorCode,
        hoveredColor: '#FFA000',
        selectedColor: '#2196F3'
      });
    }
  });
}