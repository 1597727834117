import React from 'react';
import { Chart, Series, Size } from 'devextreme-react/chart';

function BarChartComponent({ data = [] }) {
  return (
    <Chart id="chart" dataSource={data}>
      <Series
        valueField="value"
        argumentField="name"
        name="Registered Voters"
        type="bar" />
      <Size
        height={100}
        width={200}
      />
    </Chart>
  );
}


export default BarChartComponent;
