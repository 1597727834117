import React from 'react';
import PieChart from './PieChartComponent.js';

function getPartyColor(name) {

    if (name === 'PF') {
        return { color: 'green' };
    }
    else if (name === 'UPND') {
        return { color: 'red' }
    }
    else {
        return { color: '#000000' };
    }
}
function getPieData(data) {


    if (data) {
        return [
            { name: 'PF', value: data.pf2021 },
            { name: 'UPND', value: data.upnd2021 },
            { name: 'Others', value: data.other2021 }
        ];
    } else {
        return null;
    }

}

function getTitle(data, id) {


    if (data) {
        return data.name;
    } else {
        return id;
    }
}

function getProjectedWinner(data) {


    if (data) {

        if (data.pf2021 > data.upnd2021) {

            return 'PF';
        }
        else if (data.upnd2021 > data.pf2021) {
            return 'UPND';
        }
        else if (data.upnd2021 === 0 && data.pf2021 === 0) {
            return 'Waiting for Election Results';
        }
        else {
            return 'No Results Available';
        }
    } else {
        return 'No Data Availbale';
    }
}
export default function TooltipTemplate2021(info) {
    const data = info.attribute('data');
    const id = info.attribute('fid');
    const pieData = getPieData(data);

    const graphic = pieData ?
        <PieChart data={pieData}></PieChart> :
        <div>No election data availbale</div>;

    const projectedWinner = getProjectedWinner(data);
    const textStyle = getPartyColor(projectedWinner);
    const name = getTitle(data, info.attribute('fid'));

    return (
        <div>
            <h4>2021 Elections</h4>
            <h5>{name}</h5>
            {graphic}
            <h5>Winner for {name}: <span style={textStyle}>{projectedWinner}</span></h5>
            <p>Disclaimer: The results displayed here are official ECZ Results<br />Please contact ECZ for queries</p>

        </div>
    );
}
