import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.css';
import Tabs from 'devextreme-react/tabs';
import Map from './DX/Map';
import MapRegistration from './DX/MapRegistration';
import React from "react";
import MapRegistrationLusaka from './DX/MapRegistrationLusaka';
import MapLusaka from './DX/MapLusaka.js';
import MapResults2021 from './DX/MapResults2021';
import MapResultsLusaka2021 from './DX/MapResultsLusaka2021';
import axios from 'axios';
import { LoadPanel } from 'devextreme-react/load-panel';
import MapResultsLusaka2016 from './DX/MapResultsLusaka2016';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedIndex: 2,
      loading: false,
      data: null
    };
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.tabs = [
      {
        id: 0,
        text: '2021 Voter Registration',
        icon: 'card',
      },

      {
        id: 1,
        text: '2016 Election Results',
        icon: 'verticalaligntop',
      },
      {
        id: 2,
        text: '2021 Election Results',
        icon: 'group',
      },
      {
        id: 3,
        text: '2021 Voter Registration (Lusaka)',
        icon: 'card',
      },
      {
        id: 4,
        text: '2016 Election Results (Lusaka)',
        icon: 'verticalaligntop',
      },
      {
        id: 5,
        text: '2021 Election Results (Lusaka)',
        icon: 'group',
      },
    ];
  }

  componentDidMount() {
    // Typical usage (don't forget to compare props):
    console.log("Ready to post data");

    const bodyFormData = new FormData();

    bodyFormData.append('task', 'GetElectionData');
    bodyFormData.append('key', 'pa5kehsdyqdLe4Anf4hfPWEnZLtYaFxy');

    this.setState({ loading: true });

    //onlinehttps://elections.zambianbusinesstimes.com/app/ApiClient.php
    axios.post('https://elections.zambianbusinesstimes.com/app/ApiClient.php', bodyFormData, {
      auth: {
        username: 'E4380721',
        password: 'Rabecca#1990'
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .then(response => {
        this.setState({ loading: false });
        console.log(response);

        if (response.data.Succeeded) {
          this.setState({data:response.data.Data})
        }

      })
      .catch(error => {
        this.setState({ loading: false });
        console.error('There was an error!');
        console.error(error);
      });

  }

  render() {
    const selectedIndex = this.state.selectedIndex;
    return (
      <React.Fragment>
        {this.state.loading &&

          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            message='Loading data... Please wait'
            visible={this.state.loading}
          />
        }
        {!this.state.loading && this.state.data != null &&
          <div id="tabs">
            <Tabs
              dataSource={this.tabs}
              selectedIndex={selectedIndex}
              onOptionChanged={this.onTabsSelectionChanged}
            />
            {selectedIndex === 0 &&

              <MapRegistration data={this.state.data}></MapRegistration>

            }
            {selectedIndex === 1 &&

              <Map data={this.state.data}></Map>

            }
            {selectedIndex === 2 &&

              <MapResults2021 data={this.state.data}></MapResults2021>
            }
            {selectedIndex === 3 &&

              <MapRegistrationLusaka data={this.state.data}></MapRegistrationLusaka>

            }
            {selectedIndex === 4 &&


              <MapResultsLusaka2016 data={this.state.data}></MapResultsLusaka2016>

            }
            {selectedIndex === 5 &&

              <MapResultsLusaka2021 data={this.state.data}></MapResultsLusaka2021>

            }
          </div>
        }
      </React.Fragment >

    );

  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      this.setState({
        selectedIndex: args.value
      });

    }
  }

  onValueChanged(args) {
    this.setState({
      selectedIndex: args.value
    });
  }
}
export default App;
