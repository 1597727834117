import React from 'react';

import VectorMap, {
  Layer,
  Label,
  Tooltip,
  Legend,
  Source,
} from 'devextreme-react/vector-map';

import TooltipTemplate from './tooltip';

import { lusakaMain } from './NewLusaka';
const bounds = [-180, 85, 180, -60];
const colorGroups = [0, 1, 2, 3];
const streamsPalette = ['Green', 'Red', 'Gray'];
const center = [28.15850273999481, -15.446206593140616];

var electionData = {};

export default function MapResultsLusaka2016({ data = [] }) {

  // Update the document title using the browser API
  electionData = data;

  lusakaMain.features.forEach(item => {

    const mdata = electionData[item.properties.fid];

    if (mdata) {
      
      mdata.pf2021 = Number(mdata.pf2021);
      mdata.upnd2021 = Number(mdata.upnd2021);
      mdata.other2021 = Number(mdata.other2021);

      mdata.pf = Number(mdata.pf);
      mdata.upnd = Number(mdata.upnd);
      mdata.other = Number(mdata.other);

      item.properties.name = mdata.name;
      item.properties.change = mdata.registeredChange;
      item.properties.data = mdata;
    } else {
      item.properties.name = item.properties.fid;
      item.properties.change = 0;
    }

  });


  return (
    <div>
      <VectorMap
        id="vectorMap"
        maxZoomFactor={2000}
        zoomFactor={600}
        bounds={bounds}
        center={center}
        zoomFactorChanged={zoomFactorChanged}
        onClick={clickHandler}>
        <Layer
          name='lusaka'
          dataSource={lusakaMain}
          customize={customizeLayer}
          colorGroupingField="tag"
          colorGroups={colorGroups}
          palette={streamsPalette}
        >
          <Label dataField="name" enabled={true} font={{ size: 8 }} />
        </Layer>
        <Tooltip enabled={true}
          contentRender={TooltipTemplate} />
        <Legend
          title='Political Party'
          horizontalAlignment="center"
          verticalAlignment="bottom"
          customizeText={customizeText}>
          <Source layer="lusaka" grouping="color"></Source>
        </Legend>
      </VectorMap>

    </div>
  );
}

function customizeText(arg) {

  //console.log(arg);

  if (arg.color === '#ff0000') {
    return 'Hakainde Hichilema (UPND)';
  } else if (arg.color === '#008000') {
    return 'Edger Chagwa Lungu (PF)';
  } else {
    return 'Waiting for Results';
  }
}



function zoomFactorChanged({ zoomFactor }) {
  console.log("zoom has changed");
  console.log(zoomFactor);
};


function clickHandler({ target }) {

  if (target) {
    console.log(target.attribute('fid'));
    target.selected(!target.selected());
  }
}

function customizeLayer(elements) {

  elements.forEach((element) => {

    const data = electionData[element.attribute('fid')];

    if (data) {

      let colorCode = '';

      if (data.pf > data.upnd) {

        colorCode = "green";
      }
      else {
        colorCode = "red";
      }


      element.applySettings({
        color: colorCode,
        hoveredColor: '#FFA000',
        selectedColor: '#2196F3'
      });
    }
  });
}