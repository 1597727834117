import React from 'react';
import BarChart from './BarChartComponent';


function getBarData(data) {

    if (data) {
        return [
            { name: '2016', value: data.registered },
            { name: '2021', value: data.registered2021 },
        ];
    } else {
        return null;
    }

}

function getTitle(data, id) {


    if (data) {
        return data.name;
    } else {
        return id;
    }
}

function getVoters2016(data) {
    if (data) {
        return data.registered.toLocaleString();
    } else {
        return 0;
    }
}

function getVoters2021(data) {


    if (data) {
        return data.registered2021.toLocaleString();
    } else {
        return 0;
    }
}

function getChangeColor(data) {

    if (data) {

        if (data.registeredChange < 0) {

            return { color: '#D32F2F' };
        }
        else if (data.registeredChange > 0) {
            return { color: '#388E3C' };
        }
        else {
            return { color: '#455A64' };
        }
    } else {
        return { color: '#455A64' };
    }
}

function getChange(data) {

    if (data) {

        return data.registeredChange;

    } else {
        return 0;
    }
}
export default function BarTooltipTemplate(info) {
    const data = info.attribute('data');
    const id = info.attribute('fid');

    const pieData = getBarData(data);

    const graphic = pieData ?
        <BarChart data={pieData}></BarChart> :
        <div>No election data availbale</div>;

    const change = getChange(data);

    const name = getTitle(data, id);
    const textStyle = getChangeColor(data);


    return (
        <div>
            <h4>Registered Voters</h4>
            <p><strong>2016: </strong> {getVoters2016(data)} | <strong>2021: </strong>{getVoters2021(data)}</p>
            <h5>{name}</h5>
            {graphic}
            <h5>Change for {name}: <span style={textStyle}>{change}%</span></h5>

        </div>
    );
}
