import React from 'react';
import PieChart, {
  Series,
  Label,
  Legend,
  Size,
  Connector
} from 'devextreme-react/pie-chart';

function PieChartComponent({ data = [] }) {
  return (
    <PieChart id="gdp-sectors"
      dataSource={data}
      animation={false}
      customizePoint={customizePoint}
    >
      <Series
        argumentField="name"
        valueField="value">
        <Label
          visible={true}
          customizeText={customizeText}
        >
          <Connector
            visible={true}
            width={1}
          ></Connector>
        </Label>
      </Series>
      <Size
        height={200}
        width={300}/>
      <Legend visible={false}></Legend>
    </PieChart>
  );
}

function customizeText(pointInfo) {
  return `${pointInfo.argument[0].toUpperCase()}${pointInfo.argument.slice(1)
    }: ${pointInfo.value.toLocaleString()}`;
}
function customizePoint(pointInfo) {

  if (pointInfo.argument === 'PF') {
    return { color: 'green' };
  }
  else if(pointInfo.argument === 'UPND'){
    return { color: 'red' };
  }
  else{
    return { color: 'gray' };
  }
}
export default PieChartComponent;
