import React from 'react';

import VectorMap, {
  Layer,
  Label,
  Tooltip,
  Legend,
  Source,
} from 'devextreme-react/vector-map';

import BarTooltipTemplate from './barTooltip.js';

import { lusakaMain } from './NewLusaka.js';
const bounds = [-180, 85, 180, -60];
const colorGroups = [-30, -15, 0, 15, 30];
const streamsPalette = ['#D32F2F', '#FFA000', '#00802b', '#00e64d'];
const streamsNames = {
  '#d32f2f': '> 15% Decrease',
  '#ffa000': '0 - 15% Decrease',
  '#00802b': '0 - 15% Increase',
  '#00e64d': '> 15% Increase'
};
const center = [28.15850273999481, -15.446206593140616];

var electionData = {};

export default function MapRegistrationLusaka({ data = [] }) {

  // Update the document title using the browser API
  electionData = data;

  lusakaMain.features.forEach(item => {

    const mdata = electionData[item.properties.fid];

    if (mdata) {

      item.properties.name = mdata.name;
      item.properties.change = mdata.registeredChange;
      item.properties.data = mdata;
    } else {
      item.properties.name = item.properties.fid;
      item.properties.change = 0;
    }

  });

  const customizeText = (arg) => {

    //console.log(arg);
    return streamsNames[arg.color];
  }


  return (
    <div>
      <VectorMap
        id="vectorMap"
        maxZoomFactor={3000}
        zoomFactor={600}
        bounds={bounds}
        center={center}
        //onZoomFactorChanged={zoomFactorChanged}
        //onCenterChanged={centerChanged}
        onClick={clickHandler}>
        <Layer
          name='lusaka'
          dataSource={lusakaMain}
          customize={customizeLayer}
          colorGroupingField="change"
          colorGroups={colorGroups}
          palette={streamsPalette}
        >
          <Label dataField="name" enabled={true} font={{ size: 8 }} />
        </Layer>
        <Tooltip enabled={true}
          contentRender={BarTooltipTemplate} />
        <Legend
          title='Registered Voters Change'
          horizontalAlignment="center"
          verticalAlignment="bottom"
          customizeText={customizeText}>
          <Source layer="lusaka" grouping="color"></Source>
        </Legend>
      </VectorMap>

    </div>
  );
}



function zoomFactorChanged({ zoomFactor }) {
  console.log("zoom has changed");
  console.log(zoomFactor);
}

function centerChanged({ center }) {
  console.log("center has changed");
  console.log(center);
}

function clickHandler({ target }) {

  if (target) {
    console.log(target.attribute('fid'));
    target.selected(!target.selected());
  }
}

function customizeLayer(elements) {

  elements.forEach((element) => {

    const data = electionData[element.attribute('fid')];

    if (data) {

      let colorCode = '';

      if (data.pf > data.upnd) {

        colorCode = "green";
      }
      else if (data.upnd > data.pf) {

        colorCode = "red";
      }
      else {
        colorCode = "gray";
      }


      element.applySettings({
        color: colorCode,
        hoveredColor: '#607D8B',
        selectedColor: '#2196F3'
      });
    }
  });
}